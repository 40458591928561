import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import axios from 'axios';
import WhatsAppWidget from './widget/WhatsAppWidget';

function Dashboard() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [keyid, setKeyid] = useState('');
  const [telp, setTelp] = useState('');
  const [password, setPassword] = useState('');
  const [cart, setCart] = useState(0);
  const [data, setData] = useState({});
  const [orderproductid, setOrderproductid] = useState('');
  const [isorderproductidexist, setIsorderproductidexist] = useState(false);
  const [dataProduct, setDataProduct] = useState([]);

  const firebaseUrl = `https://databasepython-18128-default-rtdb.asia-southeast1.firebasedatabase.app/Akun_trifianherbal/${keyid}/cart`;
  const firebaseProductUrl = 'https://databasepython-18128-default-rtdb.asia-southeast1.firebasedatabase.app/Produk_trifianherbal';

  const checkIsorderproductidexist = async (product) => {
    try {
      const response = await axios.get(`${firebaseUrl}/${product['key']}.json`);
      if (response.data !== null) {
        setIsorderproductidexist(true);
        console.log('Produk sudah ada di keranjang');
      } else {
        setIsorderproductidexist(false);
        console.log('Produk belum ada di keranjang');
        postOrderproductid(product);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const postOrderproductid = async (product) => {
    try {

      const response = await axios.post(`${firebaseUrl}/${product['key']}.json`, product);
      // console.log('Data berhasil dikirim ke Firebase:', response.data);
      localStorage.setItem('cart', cart + 1);
      setCart(cart + 1);
      window.location.href = '/';
      
    } catch (error) {
      console.error('Error posting data:', error);
    }
  };

  const fetchDataProduct = async () => {
    try {
      const response = await axios.get(`${firebaseProductUrl}.json`);
      setDataProduct(response.data);
      // console.log('Data berhasil diambil dari Firebase:', response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src = 'https://cdn.jsdelivr.net/npm/@splidejs/splide@4.1.4/dist/js/splide.min.js';
    script1.async = true;
    document.body.appendChild(script1);

    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://cdn.jsdelivr.net/npm/@splidejs/splide@4.1.4/dist/css/splide.min.css';
    document.head.appendChild(link);


    // var splide = new Splide( '.splide', {
    //   type   : 'loop',
    //   perPage: 3,
    //   perMove: 1,
    // } );
    
    // splide.mount();

    // return () => {
      // document.body.removeChild(script1);
      // document.head.removeChild(link);
    // };

    const storedStatus = localStorage.getItem('isLoggedIn');
    const storedKeyid = localStorage.getItem('keyid');
    const storedTelp = localStorage.getItem('telp');
    const storedPassword = localStorage.getItem('password');
    const storedCart = localStorage.getItem('cart');
    if (storedStatus === 'true') {
      setIsLoggedIn(true);
      setKeyid(storedKeyid);
      setTelp(storedTelp);
      setPassword(storedPassword);
      setCart(Number(storedCart));
    }
    fetchDataProduct();
  }, []);

  return (
    <div>
      <h1 className='text-center my-5'>Produk Kami</h1>
      <Container className="">
      <Row>
        {Object.keys(dataProduct).map((key, index) => (
          // index % 2 === 0 && 
          (
            <Col key={key} className='col-md-2 mb-2 d-flex flex-row justify-content-center'>
              <Card className='mx-2' style={{ width: '10rem', cursor: 'pointer' }} onClick={() => {
                localStorage.setItem('productid', key);
                window.location.href = '/productdetail';
              }}>
                <Card.Img variant="top" style={{ height: '135px', width: '135px' }} src={dataProduct[key].image} />
                <Card.Body>
                  <Card.Title style={{ fontSize: '10px' }}>
                    {dataProduct[key].name}
                  </Card.Title>
                  <Card.Text style={{ color: 'orange' }}>
                    Rp. {dataProduct[key].price}
                  </Card.Text>
                  {/* <Button
                    variant="primary" onClick={() => {
                      const product = {
                        key: key,
                        name: dataProduct[key].name,
                        price: dataProduct[key].price,
                        image: dataProduct[key].image,
                        quantity: 1
                      };
                      checkIsorderproductidexist(product);
                    }}
                  >
                    Tambah ke Keranjang
                  </Button> */}
                </Card.Body>
              </Card>
            </Col>
          )
        ))}
      </Row>

        <WhatsAppWidget phoneNumber="6281358746018" />
      </Container>

    </div>
  );
}

export default Dashboard;
