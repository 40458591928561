import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import axios from 'axios';

function ProductDetail() {
  const [product , setProduct] = useState({});
  const [loading, setLoading] = useState(true);
  const [cart, setCart] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [keyid, setKeyid] = useState('');
  const [telp, setTelp] = useState('');
  const [password, setPassword] = useState('');
  const [isorderproductidexist, setIsorderproductidexist] = useState(false);

  const firebaseUrl = `https://databasepython-18128-default-rtdb.asia-southeast1.firebasedatabase.app/Akun_trifianherbal/${keyid}/cart`;
  const firebaseProductUrl = 'https://databasepython-18128-default-rtdb.asia-southeast1.firebasedatabase.app/Produk_trifianherbal';

  useEffect(() => {
    const storedStatus = localStorage.getItem('isLoggedIn');
    const storedKeyid = localStorage.getItem('keyid');
    const storedTelp = localStorage.getItem('telp');
    const storedPassword = localStorage.getItem('password');
    const storedCart = localStorage.getItem('cart');
    if (storedStatus === 'true') {
      setIsLoggedIn(true);
      setKeyid(storedKeyid);
      setTelp(storedTelp);
      setPassword(storedPassword);
      setCart(Number(storedCart));
    }
    fetchTransaction();
  }, []);

  const checkIsorderproductidexist = async (product) => {
    try {
      const response = await axios.get(`${firebaseUrl}/${product['key']}.json`);
      if (response.data !== null) {
        setIsorderproductidexist(true);
        console.log('Produk sudah ada di keranjang');
      } else {
        setIsorderproductidexist(false);
        console.log('Produk belum ada di keranjang');
        postOrderproductid(product);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchTransaction = async () => {
    try {
      const storagedSelectedTransaction = localStorage.getItem('productid');
      const response = await axios.get(`${firebaseProductUrl}/${storagedSelectedTransaction}.json`);
      setLoading(false);
      setProduct(response.data)
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  const postOrderproductid = async (product) => {
    try {

      const response = await axios.post(`${firebaseUrl}/${product['key']}.json`, product);
      // console.log('Data berhasil dikirim ke Firebase:', response.data);
      localStorage.setItem('cart', cart + 1);
      setCart(cart + 1);
      window.location.href = '/';
      
    } catch (error) {
      console.error('Error posting data:', error);
    }
  };

  return (
    <div className='mt-5 py-5' style={{backgroundColor:'#f1f1f1'}}>

      <div className='container bg-white'>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <div className='row pt-3'>
              <div className='col-md-3'>
                <img src={product.image} alt={product.name} style={{width: '200px'}} />
              </div>
              <div className='col-md-9'>
                <h4><b></b>{product.name}</h4>
                <p>Merek/Penerbit <b>{product.merk}</b></p>
                <hr />
                <p>Harga:</p>
                <p style={{fontSize: '20px', color: 'orange'}}>Rp {product.price}</p>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <p style={{fontSize:'10px',backgroundColor: 'blue', color: 'white',padding: '5px', borderRadius: '10px'}}>Barang Tidak Kena PPN</p>
              </div>
                <hr />
                <p>Stok Tersedia: <span style={{color: '#3bd132'}}>{product.stock}</span></p>
                <p>Jumlah Yang Ingin Dibeli</p>
                {/* jumlah yang ingin dibeli */}
                <div style={{display: 'flex', width: '150px', justifyContent: 'space-between'}}>
                  <button className='btn btn-secondary' onClick={() => {
                      var totalorder=document.getElementById('totalorder').innerHTML;
                      if (totalorder > 1) {
                        document.getElementById('totalorder').innerHTML = totalorder - 1;
                      }
                    }}>-</button>
                  <p id="totalorder">1</p>
                  <button className='btn btn-primary' onClick={() => {
                      var totalorder=document.getElementById('totalorder').innerHTML;
                      if (totalorder < product.stock) {
                        document.getElementById('totalorder').innerHTML = Number(totalorder) + 1;
                      }
                    }
                  }>+</button>
                </div>

                <p style={{color:'red'}}>Minimal pembelian mulai dari 1 barang</p>
                <div className='mb-5'>
                  <button className='btn btn-success m-1'>Beli Sekarang</button>
                  <Button
                      variant="primary" onClick={() => {
                        // casting to number
                        product.quantity = Number(document.getElementById('totalorder').innerHTML);
                        checkIsorderproductidexist(product);
                      }}
                    >
                      Tambah ke Keranjang
                  </Button>
                </div>

              </div>
            </div>
          )}

      </div>

      <div className='container bg-white my-3'>
        <h2 className='pt-3'>Spesifikasi</h2>
        <p><b>Informasi Produk</b></p> 
        <p>No Sku : {product.sku}</p>
        <p> Kategori : {product.category}</p>
        <p>Merek/Penerbit : {product.merk}</p>
        <p>Kondisi : {product.condition}</p>
        <p>Garansi : {product.guarantee}</p>
        <p>Panjang :  {product.length}</p>
        <p>Lebar :  {product.width}</p>
        <p>Tinggi :  {product.height}</p>
        <p>Berat :  {product.weight}</p>
        <p>Waktu Pengiriman :  {product.time }</p>
        <p>Cara Pengiriman :  {product.delivery}</p>
        <p> <b>Deskripsi Produk</b> </p>
        <p className='pb-4'>{product.description}</p>
      </div>

    </div>
  );
}

export default ProductDetail;
