import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import axios from 'axios';

function WpAdmin() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [keyid, setKeyid] = useState('');
  const [telp, setTelp] = useState('');
  const [password, setPassword] = useState('');
  const [cart, setCart] = useState(0);
  const [data, setData] = useState({});
  const [orderproductid, setOrderproductid] = useState('');
  const [isorderproductidexist, setIsorderproductidexist] = useState(false);
  const [dataProduct, setDataProduct] = useState([]);
  

  const firebaseUrl = `https://databasepython-18128-default-rtdb.asia-southeast1.firebasedatabase.app/Akun_trifianherbal/${keyid}/cart`;
  const firebaseProductUrl = 'https://databasepython-18128-default-rtdb.asia-southeast1.firebasedatabase.app/Produk_trifianherbal';

  const fetchDataProduct = async () => {
    try {
      const response = await axios.get(`${firebaseProductUrl}.json`);
      setDataProduct(response.data);
      // console.log('Data berhasil diambil dari Firebase:', response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const storedStatus = localStorage.getItem('isLoggedIn');
    const storedKeyid = localStorage.getItem('keyid');
    const storedTelp = localStorage.getItem('telp');
    const storedPassword = localStorage.getItem('password');
    const storedCart = localStorage.getItem('cart');
    if (storedStatus === 'true') {
      setIsLoggedIn(true);
      setKeyid(storedKeyid);
      setTelp(storedTelp);
      setPassword(storedPassword);
      setCart(Number(storedCart));
    }
    fetchDataProduct();
  }, []);
  return (
    <div>
      <h1 className='text-center pt-5'>List Produk</h1>
      {/* rata kanan */}
      <Button href='/admin/product/add' className='float-right mr-5'>Tambah Produk</Button>
      <Container className="">
        <Row>
          {Object.keys(dataProduct).map((key) => (
            <Col className='my-2' key={key}>
              <Card style={{ width: '21rem' }}>
                <Card.Img variant="top" src={dataProduct[key].image} />
                <Card.Body>
                <Card.Title>
                    <a href={`/productdetail`} 
                    onClick={() => {
                      localStorage.setItem('productid', key);
                    }}
                    > {dataProduct[key].name}</a>
                </Card.Title>
                  <Card.Text>
                    Rp. {dataProduct[key].price}
                  </Card.Text>
                  <Button className='mx-1' href={`/admin/product/edit/${key}`} variant="primary">Edit</Button>
                  <Button className='mx-1' onClick={
                    async () => {
                      try {
                        const response = await axios.delete(`${firebaseProductUrl}/${key}.json`);
                        console.log('Data berhasil dihapus:', response.data);
                        alert('Data berhasil dihapus');
                        window.location.href = '/wpadmin';
                      } catch (error) {
                        console.error(error);
                      }
                    }
                  } variant="danger">Hapus</Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        
      </Container>
    </div>
  );
}

export default WpAdmin;
