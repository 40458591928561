import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function EditProduct() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [keyid, setKeyid] = useState('');
  const [telp, setTelp] = useState('');
  const [password, setPassword] = useState('');
  const [cart, setCart] = useState(0);
  const [data, setData] = useState({});
  const [orderproductid, setOrderproductid] = useState('');
  const [isorderproductidexist, setIsorderproductidexist] = useState(false);
  const [dataProduct, setDataProduct] = useState([]);
  const [formData, setFormData] =  useState('');
  const [idProduct, setIdProduct] = useState('');
  let { id } = useParams();

  const firebaseUrl = `https://databasepython-18128-default-rtdb.asia-southeast1.firebasedatabase.app/Akun_trifianherbal/${keyid}/cart`;
  const firebaseProductUrl= `https://databasepython-18128-default-rtdb.asia-southeast1.firebasedatabase.app/Produk_trifianherbal/${id}`;

  const fetchDataProduct = async () => {
    try {
      const response = await axios.get(`${firebaseProductUrl}.json`);
      setFormData(response.data);
      console.log('Data berhasil diambil dari Firebase:', response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
      
  useEffect(() => {
    const storedStatus = localStorage.getItem('isLoggedIn');
    const storedKeyid = localStorage.getItem('keyid');
    const storedTelp = localStorage.getItem('telp');
    const storedPassword = localStorage.getItem('password');
    const storedCart = localStorage.getItem('cart');
    
    if (storedStatus === 'true') {
      setIsLoggedIn(true);
      setKeyid(storedKeyid);
      setTelp(storedTelp);
      setPassword(storedPassword);
      setCart(Number(storedCart));
    }
    fetchDataProduct();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Membuat root dengan nol di depan jika kurang dari 4 digit
      const root = formData.sku.toString().padStart(4, '0');
      // update data
      const response = await axios.put(`${firebaseProductUrl}.json`, {...formData, root});
      console.log('Data berhasil diedit:', response.data);
      alert('Data berhasil diedit');
      window.location.href = '/wpadmin';
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h1 className='text-center pt-5'>Tambah Produk</h1>
      <Container className="">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Nama Produk</label>
            <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="category">Kategori</label>
            <input type="text" className="form-control" id="category" name="category" value={formData.category} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="condition">Kondisi</label>
            <input type="text" className="form-control" id="condition" name="condition" value={formData.condition} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="delivery">Pengiriman</label>
            <input type="text" className="form-control" id="delivery" name="delivery" value={formData.delivery} onChange={handleChange} />
          </div>
          {/* Add other fields similarly */}
          <div className="form-group">
            <label htmlFor="description">Deskripsi</label>
            <input type="text" className="form-control" id="description" name="description" value={formData.description} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="guarantee">Garansi</label>
            <input type="text" className="form-control" id="guarantee" name="guarantee" value={formData.guarantee} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="height">Tinggi</label>
            <input type="text" className="form-control" id="height" name="height" value={formData.height} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="image">Gambar</label>
            <input type="text" className="form-control" id="image" name="image" value={formData.image} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="length">Panjang</label>
            <input type="text" className="form-control" id="length" name="length" value={formData.length} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="merk">Merk</label>
            <input type="text" className="form-control" id="merk" name="merk" value={formData.merk} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="price">Harga</label>
            <input type="text" className="form-control" id="price" name="price" value={formData.price} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="sku">SKU</label>
            <input type="text" className="form-control" id="sku" name="sku" value={formData.sku} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="stock">Stok</label>
            <input type="text" className="form-control" id="stock" name="stock" value={formData.stock} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="time">Waktu</label>
            <input type="text" className="form-control" id="time" name="time" value={formData.time} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="weight">Berat</label>
            <input type="text" className="form-control" id="weight" name="weight" value={formData.weight} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="width">Lebar</label>
            <input type="text" className="form-control" id="width" name="width" value={formData.width} onChange={handleChange} />
          </div>
          <button type="submit" className="btn btn-primary">Submit</button>
        </form>
      </Container>
    </div>
  );
}

export default EditProduct;
