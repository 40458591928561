import React, { useState, useEffect, useCallback  } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

function Cart() {
  const [keyid, setKeyid] = useState('');
  const [cartItems, setCartItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [cartCount, setCartCount] = useState(0); // Added cartCount state
  const [checkOutItems, setCheckOutItems] = useState([]); // Added checkOut state

  const firebaseUrl = `https://databasepython-18128-default-rtdb.asia-southeast1.firebasedatabase.app/Akun_trifianherbal`;

  const fetchCartItems = useCallback(async (storedKeyid) => {
    try {
      const response = await axios.get(`${firebaseUrl}/${storedKeyid}/cart.json`);
      let newcartItems = [];
      let count = 0;
      for (let key in response.data) {
        const item = response.data[key];
        const getContain = Object.values(item)[0];
        newcartItems.push({ ...getContain, key });
        count++;
      }
      setCartItems(newcartItems);
      setIsLoading(false);
      setCartCount(count);
      localStorage.setItem('cart', count);
    } catch (error) {
      console.error('Error fetching cart data:', error);
      setIsLoading(false);
    }
  }, [firebaseUrl]);

  useEffect(() => {
    const storedKeyid = localStorage.getItem('keyid');
    if (storedKeyid) {
      setKeyid(storedKeyid);
      fetchCartItems(storedKeyid);
    }
  }, [fetchCartItems]); // Include fetchCartItems in the dependency array

  const handleRemoveFromCart = async (key) => {
    try {
      await axios.delete(`${firebaseUrl}/${keyid}/cart/${key}.json`);
      setCartItems(cartItems.filter(item => item.key !== key));
      setCartCount(cartCount - 1); // Update cartCount
      localStorage.setItem('cart', cartItems.length - 1);
      window.location.href = '/cart';
    } catch (error) {
      console.error('Error removing item from cart:', error);
    }
  };

  const handleCheckout = () => {
    const checkoutData = cartItems.map(item => ({
      name: item.name,
      price: item.price,
      quantity: item.quantity
    }));

    setCheckOutItems(checkoutData);
    localStorage.setItem('checkOutItems', JSON.stringify(checkoutData));

    setCartItems([]);
    setCartCount(0);
    localStorage.setItem('cart', 0);
    window.location.href = '/checkout';

  };

  const handleIncreaseQuantity = (itemKey) => {
    const updatedCartItems = cartItems.map(item =>
      item.key === itemKey ? { ...item, quantity: item.quantity + 1 } : item
    );
    setCartItems(updatedCartItems);
  };

  const handleDecreaseQuantity = (itemKey) => {
    const updatedCartItems = cartItems.map(item =>
      item.key === itemKey ? { ...item, quantity: Math.max(item.quantity - 1, 1) } : item
    );
    setCartItems(updatedCartItems);
  };

  return (
    <Container className="mt-5">
      <h2 className='pt-5'>Keranjang</h2>
      {isLoading ? (
        <p>Loading...</p>
      ) : cartItems.length === 0 ? (
        <p>Keranjangmu Kosong.</p>
      ) : (
        <>
          <p>Jumlah Jenis Item: {cartCount}</p>
          <Row>
            {cartItems.map((item) => (
              <Col md={4} key={item.key}>
                <Card className="mb-3">
                  <Card.Body>
                    <Card.Img className='img-fluid' variant="top" src={item.image} />
                    <Card.Title>{item.name}</Card.Title>
                    <Card.Text>Rp. {item.price}</Card.Text>
                    <div className="d-flex align-items-center">
                      <Button
                        variant="outline-primary"
                        onClick={() => handleDecreaseQuantity(item.key)}
                      >
                        -
                      </Button>
                      <div className="mx-2">{item.quantity}</div>
                      <Button
                        variant="outline-primary"
                        onClick={() => handleIncreaseQuantity(item.key)}
                      >
                        +
                      </Button>
                    </div>
                    <Button
                      variant="primary"
                      onClick={() => handleRemoveFromCart(item.key)}
                      className='mt-3'
                    >
                      Hapus
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </>
      )}
      <div className="d-flex justify-content-end">
        {cartItems.length > 0 && (
          <Button variant="success" onClick={handleCheckout} className="my-3">
            <i className="fas fa-money-bill-wave"></i> Checkout {/* Add money icon */}
          </Button>
        )}
      </div>
    </Container>
  );
}

export default Cart;
