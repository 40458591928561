import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import './script.js';

function MyNavbar() {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State untuk status login
  const [keyid, setKeyid] = useState(''); // State untuk menyimpan key id dari data yang akan dihapus
  const [telp, setTelp] = useState('');
  const [password, setPassword] = useState('');
  const [cart, setCart] = useState(0); // State untuk menyimpan data cart

  useEffect(() => {
    const storedStatus = localStorage.getItem('isLoggedIn');
    const storedKeyid = localStorage.getItem('keyid');
    const storedTelp = localStorage.getItem('telp');
    const storedPassword = localStorage.getItem('password');
    const storedCart = Number(localStorage.getItem('cart'));
    if (storedStatus === 'true') {
      setIsLoggedIn(true);
      setKeyid(storedKeyid);
      setTelp(storedTelp);
      setPassword(storedPassword);
      setCart(Number(storedCart));
    }
  }, []);

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('isLoggedIn');
  };

  return (
    <>
    
      <link rel="stylesheet" type="text/css" href="./style.css" />

      {/* Navbar */}
      <nav className="navbar navbar-expand-lg navbar-light fixed-top" style={{backgroundColor:'#0894dc'}}>
        <img className="p-1 rounded-circle" src="https://thumbs.dreamstime.com/b/shopping-bag-logo-design-icon-online-shop-symbol-vector-illustrations-discount-flat-sales-digital-commerce-store-simple-marketing-157881334.jpg" alt="Logo Izintermurah.com" height="40" width="40"/>
        <a className="navbar-brand text-white" href="/">Shoopkit</a>
        <ul className="navbar-nav ml-auto d-flex flex-row">
          {isLoggedIn ? (
            <>
              <li className="nav-item text-white pt-1 px-1">
                <a href='/cart' className="badge bg-white rounded-pill position-relative mx-1">
                  <i className="fas fa-shopping-cart fa-2x"></i>
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-dark">
                    {cart}
                  </span>
                </a>
              </li>
              <li className="nav-item pt-1 px-1">
                <a href='/transaction' className="badge bg-white rounded-pill position-relative mx-1">
                  <i className="fas fa-dollar-sign fa-2x"></i>
                </a>
              </li>
              <li className="nav-item dropdown pt-1 px-1">
                <a className="nav-link dropdown-toggle text-white badge rounded-pill position-relative" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="fas fa-user fa-2x"></i>
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <a className="dropdown-item" href="#">{telp}</a>
                  <a className="dropdown-item" href="/" onClick={handleLogout}>Log Out</a>
                </div>
              </li>
            </>
          ) : (
            <>
              <li className="nav-item mx-1">
                <Link to="/login" className="nav-link text-white">Login</Link>
              </li>
              <li className="nav-item mx-1">
                <a className="nav-link text-white" href="/register">Register</a>
              </li>
            </>
          )}
        </ul>
      </nav>
      {/* Pencarian dan Kategori */}
      <div className="py-2" style={{ marginTop: '50px',backgroundColor:'#0894dc' }}>
        <div className="container d-flex flex-column flex-lg-row justify-content-lg-center" 
        // style={{paddingBottom: '20px'}}
        >
          <form className="form-inline my-2 my-lg-0 flex-lg-row flex-column justify-content-lg-start justify-content-center order-lg-2">
            <select className="form-control mr-sm-2 mb-2 mb-lg-0" id="kategori">
              <option value="all">Semua Kategori</option>
              <option value="electronics">Electronics</option>
              <option value="clothing">Clothing</option>
              <option value="books">Books</option>
              {/* Tambahkan kategori lain sesuai kebutuhan */}
            </select>

            <input className="form-control mr-sm-2 mb-2 mb-lg-0" type="search" placeholder="Ingin Cari Produk Apa?" aria-label="Search" id='search'/>
            
            <button onClick={
              () => {
                const kategori = document.getElementById('kategori').value;
                window.location.href = `/search?kategori=${kategori}`;
              }
            
            } className="btn btn-outline-light my-2 my-sm-0" type="submit" style={{height: '50px'}}>Search</button>
          </form>
        </div>
      </div>
    </>


  );
}

export default MyNavbar;
