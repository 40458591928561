// src/components/WhatsAppWidget.js
import React from 'react';

const WhatsAppWidget = ({ phoneNumber }) => {
  const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=Halo%20Trifianherbal%20Saya%20Mau%20Konsultasi`;

  return (
    <div className='whatsapp-widget fixed-bottom d-flex justify-content-end m-1'>
      <a href={whatsappUrl} className='whatsapp-link bg-success rounded'>
        <img src="https://img.icons8.com/color/48/000000/whatsapp--v1.png" alt="WhatsApp" className='whatsapp-icon img-fluid' />
        <span className='text-white p-2'>Konsultasi</span>
      </a>
    </div>
  );
};

export default WhatsAppWidget;
