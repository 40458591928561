import React, { useState, useEffect } from 'react';
import axios from 'axios';

function LoginForm() {
  const [data, setData] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State untuk status login
  const [telp, setTelp] = useState('');
  const [password, setPassword] = useState('');
  const [cart, setCart] = useState(0); // State untuk menyimpan data cart
  const [keyid, setKeyid] = useState(''); // State untuk menyimpan key id dari data yang akan dihapus

  const firebaseUrl = "https://databasepython-18128-default-rtdb.asia-southeast1.firebasedatabase.app/Akun_trifianherbal";

  const restoreDataFromLocalStorage = () => {
    const storedTelp = localStorage.getItem('telp');
    const storedPassword = localStorage.getItem('password');

    if (storedTelp) {
      setTelp(storedTelp);
    }

    if (storedPassword) {
      setPassword(storedPassword);
    }
  };

  // Fungsi untuk melakukan login
  const handleLogin = () => {
    // Lakukan proses login di sini
    // Set isLoggedIn ke true setelah berhasil login
    setIsLoggedIn(true);
    // Simpan status login di localStorage
    localStorage.setItem('isLoggedIn', 'true');
  };

  // Fungsi untuk mengambil data dari Firebase
  const fetchData = async () => {
    try {
      const response = await axios.get(firebaseUrl + '.json');
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Fungsi untuk mengirim data baru ke Firebase
  const postData = async () => {
    try {
      const response = await axios.get(firebaseUrl + '.json');
      setData(response.data);
      for (let key in data) {
        if (data[key].telp === telp && data[key].password === password) {
          localStorage.setItem('isLoggedIn', 'true');
          localStorage.setItem('keyid', key);
          localStorage.setItem('telp', telp);
          localStorage.setItem('password', password);
          localStorage.setItem('cart', Number(0));
          window.location.href = '/';
          return;
        }
      }
      localStorage.setItem('telp', telp);
      localStorage.setItem('password', password);
      setKeyid('');
      setTelp('');
      setPassword('');
      setCart('');
      fetchData(); // Memanggil fetchData kembali setelah posting berhasil
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  useEffect(() => {
    restoreDataFromLocalStorage();
    fetchData();
  }, []);

  return (
    <div className="container mt-5">
      <h1 className="text-center">Login</h1>
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <div className="mb-3">
                <label className="form-label">Nomer Whatsapp</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Masukkan Nomer Whatsapp"
                  value={telp}
                  onChange={(e) => setTelp(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Masukkan Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button className="btn btn-primary" onClick={postData}>
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
