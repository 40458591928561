import React, { useState, useEffect } from 'react';
import axios from 'axios';

function RegisterForm() {
  const [data, setData] = useState({});
  const [telp, setTelp] = useState('');
  const [password, setPassword] = useState('');
  const [cart, setCart] = useState(0); // State untuk menyimpan data cart

  const firebaseUrl = "https://databasepython-18128-default-rtdb.asia-southeast1.firebasedatabase.app/Akun_trifianherbal";

  // Fungsi untuk mengambil data dari Firebase
  const fetchData = async () => {
    try {
      const response = await axios.get(firebaseUrl + '.json');
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Fungsi untuk mengirim data baru ke Firebase
  const postData = async () => {
    try {
      await axios.post(firebaseUrl + '.json', {
        telp: telp,
        password: password,
        cart: 0
      });
      setTelp('');
      setPassword('');
      setCart('');
      fetchData(); // Memanggil fetchData kembali setelah posting berhasil
      window.location.href = '/login';
      return;
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container mt-5">
      <h1 className="text-center">Akun Baru</h1>
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <div className="mb-3">
                <label className="form-label">Nomer Whatsapp</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Masukkan Nomer Whatsapp"
                  value={telp}
                  onChange={(e) => setTelp(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Masukkan Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button className="btn btn-primary" onClick={postData}>
                Daftar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterForm;
