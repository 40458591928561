import React, { useEffect, useState } from 'react';
import { Container, Card, Modal, Table } from 'react-bootstrap';

function TransactionDetail() {
  const [selectedTransaction, setSelectedTransaction] = useState({});
  const [itemOrder, setItemOrder] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false); // State untuk mengontrol modal
  const [mapUrl, setMapUrl] = useState(''); // URL untuk peta
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchTransaction();
  }, []);

  const fetchTransaction = async () => {
    try {
      const storagedSelectedTransaction = localStorage.getItem('selectedTransaction');
      let json=JSON.parse(storagedSelectedTransaction);
      setSelectedTransaction(json);
      setItemOrder(JSON.parse(storagedSelectedTransaction).items);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  const handleCekResi = () => {
    let courier=''
    if(selectedTransaction.courier==="shopee"){
      courier="spx";
    }
    else if(selectedTransaction.courier==="jne"){
      courier="jne";
    }
    setMapUrl(`https://www.cekpengiriman.com/cek-resi?resi=${selectedTransaction.resi}&kurir=${courier}`);
  };


  return (
    <div>
      <h2 className='text-center py-3'>Transaction Detail</h2>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className='container'>
          <p><b>Nomor Transaksi: </b>{selectedTransaction.order_id}</p>
          <p><b>Status: </b>{selectedTransaction.status}</p>
          <p><b>Total Pembayaran: </b>{selectedTransaction.total_price}</p>
          <p><b>Alamat: </b>{selectedTransaction.address}</p>
          <p><b>Desa: </b>{selectedTransaction.village}</p>
          <p><b>Kecamatan: </b>{selectedTransaction.district}</p>
          <p><b>Kota/Kabupaten: </b>{selectedTransaction.city}</p>
          <p><b>Provinsi: </b>{selectedTransaction.province}</p>
          <p><b>COD: </b>{selectedTransaction.cod}</p>
          {selectedTransaction.resi ? (
            <>
              <p><b>Resi: </b>
                <a onClick={handleCekResi} href={mapUrl}>
                  {selectedTransaction.resi}
                </a>
              </p>
              <p><b>Kurir: </b>{selectedTransaction.courier}</p>
            </>
          ) : (
            <></>
          )}
          <div>
          <h3 className='mt-5'>Produk Yang Dibeli</h3>
          <Table striped bordered>
            <thead>
              <tr>
                <th>Nama Produk</th>
                <th>Harga</th>
                <th>Jumlah</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {itemOrder.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{item.price}</td>
                  <td>{item.quantity}</td>
                  <td>{item.price * item.quantity}</td>
                </tr>
              ))}
              <tr>
                <td colSpan="3" className="text-right">Ongkir</td>
                <td>{selectedTransaction.ongkir}</td>
              </tr>
              <tr>
                <td colSpan="3" className="text-right">Total Harga</td>
                <td>{selectedTransaction.total_price}</td>
              </tr>
            </tbody>
          </Table>
          </div>
        </div>
      )}

    </div>
  );
}

export default TransactionDetail;
