import React from 'react';

function Example() {
  return (
    <div className="App">
      <h1>Contoh Iframe Web Lain</h1>
      <iframe
        title="Iframe Web Lain"
        src="https://izintermurah.com/"
        width="800"
        height="600"
        frameBorder="0"
      />
    </div>
  );
}

export default Example;
