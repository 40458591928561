import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function MyFooter() {
  return (
    <footer className="text-center text-white" style={{ backgroundColor:'#0894dc' }}>
      <Container>
        <Row>
          <Col>
            <section className="mb-4">
              <h3 className="my-3">Contact Us</h3>
              <a className="btn text-white btn-floating mx-2" style={{ backgroundColor: '#3b5998' }} href="https://www.google.com/search?q=trifianherbal" role="button">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a className="btn text-white btn-floating mx-2" style={{ backgroundColor: '#dd4b39' }} href="/" role="button">
                <i className="fab fa-google"></i>
              </a>
              <a className="btn text-white btn-floating mx-2" style={{ backgroundColor: '#ac2bac' }} href="https://www.instagram.com/trifianherbal/" role="button">
                <i className="fab fa-instagram"></i>
              </a>
            </section>
          </Col>
        </Row>
      </Container>

      <div className="text-white-50 text-center pb-3" style={{ backgroundColor:'#0894dc' }}>
        <p>@ 2024 Shoopkit - All rights reserved</p>
      </div>
    </footer>
  );
}

export default MyFooter;
