import React, { useState, useEffect } from 'react';
import { Container, Card, Modal } from 'react-bootstrap';
import axios from 'axios';

function Transaction() {
  const [transactions, setTransactions] = useState([]);
  const [keyid, setKeyid] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const firebaseUrl = `https://databasepython-18128-default-rtdb.asia-southeast1.firebasedatabase.app/Transaction_trifianherbal`;

  useEffect(() => {
    fetchTransactions();
  }, []);

  const fetchTransactions = async () => {
    try {
      const keyid = localStorage.getItem('keyid');
      setKeyid(keyid);
      const response = await axios.get(`${firebaseUrl}.json`);
      // console.log('Data berhasil diambil dari Firebase:', response.data);
      const transactionsData = [];
      for (let key in response.data) {
        if (response.data[key].id_user === keyid) {
            const transaction = response.data[key];
            transactionsData.push({ ...transaction, key });
        }
      }
      transactionsData.reverse();
      setTransactions(transactionsData);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  const handleTransactionClick = (transaction) => {
    setSelectedTransaction(transaction);
    localStorage.setItem('selectedTransaction', JSON.stringify(transaction));
    setShowModal(true);
  };

  const handleTransactionLink = (transaction) => {
    setSelectedTransaction(transaction);
    localStorage.setItem('selectedTransaction', JSON.stringify(transaction));
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedTransaction(null);
    localStorage.removeItem('selectedTransaction');
  };

  return (
    <Container>
      <h2 className='mt-5 text-center pt-5'>Daftar Transaksi</h2>
      {transactions.length === 0 ? (
        <p>Tidak ada transaksi yang tersedia.</p>
      ) : (
        <div>
          {transactions.map(transaction => (
            <Card key={transaction.key} className='my-3'>
              <Card.Body>
               <Card.Title>
                  Nomor Transaksi:{' '}
                  <a onClick={() => handleTransactionLink(transaction)} href={`/transactiondetail`}>
                    {transaction.order_id}
                  </a>
                </Card.Title>
                <Card.Text><b>Status Pembayaran: </b>{transaction.status}</Card.Text>
                <Card.Text><b>Total Pembayaran: Rp. </b>{transaction.total_price}</Card.Text>
                <Card.Text><b>COD: </b>{transaction.cod}</Card.Text>
                { (transaction.status !== 'Pembayaran Berhasil' && transaction.cod !== 'YA') && (
                    <button className='btn btn-success' onClick={() => handleTransactionClick(transaction)}>Bayar Sekarang</button>
                )}

                
              </Card.Body>
            </Card>
          ))}
        </div>
      )}

    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Detail Transaksi</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {selectedTransaction && (
          <iframe
            src={`https://www.izintermurah.com/midtrans/${selectedTransaction.order_id}/${selectedTransaction.total_price}`}
            title="Midtrans"
            width="100%"
            height="400px"
            style={{ display: showModal ? 'block' : 'none' }}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        {showModal && selectedTransaction && (
          <>
          <a
            className='btn btn-primary text-white'
            href={`https://www.izintermurah.com/midtransstatus/${selectedTransaction.order_id}`}
            onClick={(e) => {
              e.preventDefault(); // Prevent the default behavior of the link
              // You can add any additional logic here before changing the iframe source
              const newSrc = `https://www.izintermurah.com/midtransstatus/${selectedTransaction.order_id}`;
              document.querySelector('iframe').src = newSrc;
              setTimeout(() => {
                window.location.href = '/transaction';
              }, 2000); // 5000 milliseconds = 5 seconds
            }}
          >
            Konfirmasi Pembayaran
          </a>
          <button className="btn btn-secondary" onClick={handleCloseModal}>Close</button>
          </>
        )}
      </Modal.Footer>
    </Modal>

    </Container>
  );
}

export default Transaction;
