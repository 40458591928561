import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Table, Row, Col } from 'react-bootstrap';
import axios from 'axios';


function Checkout() {
    const [telp, setTelp] = useState('');
    const [keyid, setKeyid] = useState(''); // State untuk menyimpan key id dari data yang akan dihapus
    const [ongkir, setOngkir] = useState(0);

    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
   
    const [checkoutData, setCheckoutData] = useState([]);
    const [cartItems, setCartItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalItem, setTotalItem] = useState(0); // Added totalItem state

    // State for provinces
    const [provinces, setProvinces] = useState([]);
    const [province, setProvince] = useState('');
    
    // State for cities
    const [cities, setCities] = useState([]); 
    const [city, setCity] = useState('');
 
    // State for districts
    const [districts, setDistricts] = useState([]);
    const [district, setDistrict] = useState('');
    
    // State for villages
    const [villages, setVillages] = useState([]);
    const [village, setVillage] = useState('');

    // State for cod
    const [cod, setCod] = useState('');
    const [selectedCod, setSelectedCod] = useState('');

    const [midtransResponse, setMidtransResponse] = useState([]); // State untuk menyimpan response dari midtrans
    const [token, setToken] = useState('');
    const [redirect_url, setRedirect_url] = useState('');
  
    const firebaseUrl = `https://databasepython-18128-default-rtdb.asia-southeast1.firebasedatabase.app/Akun_trifianherbal`;
  
    useEffect(() => {
        const storedTelp = localStorage.getItem('telp');
        const storedKeyid = localStorage.getItem('keyid');
        if (storedTelp) {
          setTelp(storedTelp);
          setKeyid(storedKeyid);
        }
      
        const storedCheckoutData = localStorage.getItem('checkOutItems');
        if (storedCheckoutData) {
          setCheckoutData(JSON.parse(storedCheckoutData));
        }
        fetchCartItems(storedKeyid);
        fetchProvinces();
      }, []); // Menambahkan isLoading sebagai dependensi


    const fetchProvinces = () => {
        fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/provinces.json`)
          .then(response => response.json())
          .then(provincesData => {
            
            // Memfilter data berdasarkan ID antara 31 hingga 36
            const filteredProvinces = provincesData.filter(
              province => parseInt(province.id) >= 31 && parseInt(province.id) <= 36
            );
            setProvinces(filteredProvinces);

          })
          .catch(error => {
            console.error('Error fetching provinces:', error);
          });
      };

    const fetchCities = (provinceId) => {
        fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/regencies/${provinceId}.json`)
            .then(response => response.json())
            .then(citiesData => {
            setCities(citiesData);
            })
            .catch(error => {
            console.error('Error fetching cities:', error);
            });
    };

    // Fetch districts based on selected city
    const fetchDistricts = (cityId) => {
        fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/districts/${cityId}.json`)
        .then(response => response.json())
        .then(districtsData => {
            setDistricts(districtsData);
        })
        .catch(error => {
            console.error('Error fetching districts:', error);
        });
    };

    // Fetch villages based on selected district
    const fetchVillages = (districtId) => {
        fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/villages/${districtId}.json`)
        .then(response => response.json())
        .then(villagesData => {
            setVillages(villagesData);
        })
        .catch(error => {
            console.error('Error fetching villages:', error);
        });
    };

    const handleProvinceChange = (selectedProvince) => {
        setProvince(selectedProvince);
        setCity(''); // Reset city when province changes
        setDistrict(''); // Reset district when province changes
        setVillage(''); // Reset village when province changes
        setCod(''); // Reset cod when province changes
        setSelectedCod(''); // Reset selectedCod when province changes
        fetchCities(selectedProvince);
    };

    const handleCityChange = (selectedCity) => {
        setCity(selectedCity);
        setDistrict(''); // Reset district when city changes
        setVillage(''); // Reset village when city changes
        setCod(''); // Reset cod when city changes
        setSelectedCod(''); // Reset selectedCod when city changes
        fetchDistricts(selectedCity);
    };

    const handleDistrictChange = (selectedDistrict) => {
        setDistrict(selectedDistrict);
        setVillage(''); // Reset village when district changes
        setCod(''); // Reset cod when district changes
        setSelectedCod(''); // Reset selectedCod when district changes
        fetchVillages(selectedDistrict);
    };

    const handleVillageChange = (selectedVillage) => {
        setVillage(selectedVillage);
        setCod(''); // Reset cod when village changes
        setSelectedCod(''); // Reset selectedCod when village changes
      };

    const handleCodChange = (selectedCod) => {
        let opsi_cod = '';
        if(selectedCod === '1') {
            opsi_cod = 'YA';
        }else{
            opsi_cod = 'TIDAK';
        }
        setCod(opsi_cod);
        setSelectedCod(selectedCod);


        let total_item = 0;
        let total_ongkir = 0;
        let total_price = 0;
        let ongkir_per_kg =0;

        checkoutData.forEach(item => {
            total_price += item.price * item.quantity;
            });
      
        checkoutData.forEach(item => {
          total_item += item.quantity;
        });
      
        total_ongkir = Math.ceil(total_item / 10);
        if(province === '35') {
            ongkir_per_kg = 10000;
        }else{
            ongkir_per_kg = 13000;
        }
        let cod_price = 0;
        if(opsi_cod === 'YA') {
            cod_price = Math.ceil(0.03 * (total_price+total_ongkir * ongkir_per_kg));
        }
        // ongkirnya disni
        total_ongkir=total_ongkir * ongkir_per_kg+cod_price
        setOngkir(total_ongkir); // Update the ongkir state
        const updatedTotalPrice = total_price + total_ongkir; // Calculate updated total price
        setTotalPrice(updatedTotalPrice); // Update the totalPrice state
    };
      
  
    const fetchCartItems = async (storedKeyid) => {
      try {
        const response = await axios.get(`${firebaseUrl}/${storedKeyid}/cart.json`);
        let newcartItems = [];
        for (let key in response.data) {
          const item = response.data[key];
          newcartItems.push({ ...item, key });
        }
        setCartItems(newcartItems);
      } catch (error) {
        // console.error('Error fetching cart data:', error);
      }
    }; 
    
    const postmydata  = async () => {
        try {
            setIsLoading(true); // Mengaktifkan indikator loading
            let customerDistrict = '';
            for (let key in districts) {
                if(districts[key].id === district) {
                    customerDistrict=districts[key].name
                }
            }
            let customerCity = '';
            for (let key in cities) {
                if(cities[key].id === city) {
                    customerCity=cities[key].name
                }
            }

            let customerProvince = '';
            for (let key in provinces) {
                if(provinces[key].id === province) {
                    customerProvince=provinces[key].name
                }
            }
            
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
            const day = currentDate.getDate().toString().padStart(2, '0');
            const hours = currentDate.getHours().toString().padStart(2, '0');
            const minutes = currentDate.getMinutes().toString().padStart(2, '0');
            const seconds = currentDate.getSeconds().toString().padStart(2, '0');

            const formattedDateTime = `${year}${month}${day}${hours}${minutes}${seconds}`;


            const orderTransaction = {
                order_id: 'TRIFIANHERBAL' + formattedDateTime + telp,
                id_user: keyid,
                name:name,
                address:address,
                telp:telp,
                village:village,
                district:customerDistrict,
                city:customerCity,
                province:customerProvince,
                items: checkoutData,
                ongkir:ongkir,
                total_price:totalPrice,
                status: 'Belum Melakukan Pembayaran',
                cod:cod,
                status_akutansi:'belum'
            };

            // console.log('Order data:', orderTransaction);
            const transaction_order = await axios.post('https://databasepython-18128-default-rtdb.asia-southeast1.firebasedatabase.app/Transaction_trifianherbal.json', orderTransaction);
            
            const deletecart = await axios.delete(`${firebaseUrl}/${keyid}/cart.json`);

            localStorage.setItem('cart', 0);
            localStorage.setItem('checkOutItems', []);
            alert('Pesanan berhasil dibuat');
            window.location.href = '/transaction'; // Redirect to confirmation page
        } catch (error) {
            console.error('Error sending order data:', error);
        }
    };
  
  return (
    <Container>
      <h2 className='mt-5 text-center pt-5'>Checkout</h2>
      <h3 className='mt-5'>Alamat</h3>
      <Form>
        <Row>
            <Col className='md-2'>
                <Form.Group>
                    <Form.Label>Nama</Form.Label>
                    <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} />
                </Form.Group>
            </Col>
            <Col className='md-2'>
            <Form.Group>
                <Form.Label>Telp</Form.Label>
                <Form.Control type="text" value={telp} onChange={(e) => setTelp(e.target.value)} />
            </Form.Group>
            </Col>
        </Row>
       
        <Form.Group>
          <Form.Label>Alamat</Form.Label>
          <Form.Control type="text" value={address} onChange={(e) => setAddress(e.target.value)} />
        </Form.Group>

        <Row>
            <Col className='md-2'>
                {/* Province dropdown */}
                <Form.Group controlId="province">
                    <Form.Label>Provinsi</Form.Label>
                        <Form.Control as="select" value={province} onChange={(e) => handleProvinceChange(e.target.value)}>
                            <option value="">Pilih Provinsi</option>
                            {provinces.map(province => (
                            <option key={province.id} value={province.id}>
                                {province.name}
                            </option>
                            ))}
                        </Form.Control>
                </Form.Group>
            </Col>
            <Col className='md-2'>
                 {/* City dropdown */}
                <Form.Group controlId="city">
                    <Form.Label>Kota</Form.Label>
                        <Form.Control as="select" value={city} onChange={(e) => handleCityChange(e.target.value)}>
                            <option value="">Pilih Kota</option>
                            {cities.map(city => (
                            <option key={city.id} value={city.id}>
                                {city.name}
                            </option>
                            ))}
                        </Form.Control>
                </Form.Group>
            </Col>  
        </Row>
         
        <Row>
            {/* copy district dropdow  */}
            <Col className='md-2'>
                <Form.Group controlId="district">
                    <Form.Label>Kecamatan</Form.Label>
                    <Form.Control as="select" value={district} onChange={(e) => handleDistrictChange(e.target.value)}>
                        <option value="">Pilih Kecamatan</option>
                        {districts.map(district => (
                        <option key={district.id} value={district.id}>
                            {district.name}
                        </option>
                        ))}
                    </Form.Control>
                </Form.Group>
            </Col>
            <Col className='md-2'>
                {/* Village dropdown */}
                <Form.Group controlId="village">
                    <Form.Label>Desa</Form.Label>
                    <Form.Control as="select" value={village} onChange={(e) => handleVillageChange(e.target.value)}>
                        <option value="">Pilih Desa</option>
                        {villages.map(village => (
                        <option key={village.id} value={village.name}>
                            {village.name}
                        </option>
                        ))}
                    </Form.Control>
                </Form.Group>
            </Col>
        </Row>
        <Row>
            {/* copy cod dropdow  */}
            <Col className='md-12'>
                <Form.Group controlId="cod">
                    <Form.Label>COD</Form.Label>
                    <Form.Control as="select" value={selectedCod} onChange={(e) => handleCodChange(e.target.value)}>
                        <option value="">Pilih Opsi</option>
                        <option key={1} value={1}>
                            Ya
                        </option>
                        <option key={0} value={0}>
                            Tidak
                        </option>
                    </Form.Control>
                </Form.Group>
            </Col>
        </Row>

      <h3 className='mt-5'>Produk Yang Dibeli</h3>
      <Table striped bordered>
        <thead>
          <tr>
            <th>Nama Produk</th>
            <th>Harga</th>
            <th>Jumlah</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {checkoutData.map((item, index) => (
            <tr key={index}>
              <td>{item.name}</td>
              <td>{item.price}</td>
              <td>{item.quantity}</td>
              <td>{item.price * item.quantity}</td>
            </tr>
          ))}
          <tr>
            <td colSpan="3" className="text-right">Total Ongkir</td>
            <td>{ongkir}</td>
          </tr>
          <tr>
            <td colSpan="3" className="text-right">Total Harga</td>
            <td>{totalPrice}</td>
          </tr>
        </tbody>
      </Table>
      <div className="d-flex justify-content-end">
      <Button
          variant="success"
          onClick={postmydata}
          className="my-3 py-1"
          disabled={isLoading} // Menonaktifkan tombol saat loading
        >
            {isLoading ? ( // Tampilkan indikator loading saat isLoading true
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <i className="fas fa-spinner fa-spin mr-2"></i>Loading...
            </div>
          ) : (
            <span>
              <i className="fas fa-money-bill-wave"></i> Bayar
            </span>
          )}
        </Button>
      </div>
      
      </Form>

      {isLoading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <div>
            <i className="fas fa-spinner fa-spin fa-3x" style={{ color: 'white' }}></i>
            <p style={{ color: 'white' }}>Loading...</p>
          </div>
        </div>
      )}

    </Container>
  );
}

export default Checkout;
