import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import MyNavbar from './components/template/Mynavbar';
import MyFooter from './components/template/Myfooter';
import RegisterForm from './components/auth/RegisterForm';
import LoginForm from './components/auth/LoginForm';
import Cart from './components/features/Cart';
import Checkout from './components/features/Checkout';
import Example from './components/features/Example';
import Transaction from './components/features/Transaction';
import TransactionDetail from './components/features/TransactionDetail';
import WhatsAppComponent from './components/features/WhatsAppComponent';
import ProductDetail from './components/features/ProductDetail';
import WpAdmin from './components/admin/WpAdmin';
import AddProduct from './components/admin/AddProduct';
import EditProduct from './components/admin/EditProduct';

const App = () => {
  return (
    <Router>
      <div>
        <MyNavbar />
        <div>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/register" element={<RegisterForm />} />
          <Route path="/cart" element={<Cart />} />
          <Route path='/checkout' element={<Checkout/>} />
          <Route path='/transaction' element={<Transaction/>} />
          <Route path='/example' element={<Example/>} />
          <Route path="/transactiondetail" element={<TransactionDetail/>} />        
          <Route path="/whatsappapi" element={<WhatsAppComponent />} />  
          <Route path="/productdetail" element={<ProductDetail />} />
          <Route path="/wpadmin" element={<WpAdmin />} />
          <Route path="/admin/product/add" element={<AddProduct />} />
          <Route path="/admin/product/edit/:id" element={<EditProduct />} />
          <Route path="/search?kategori=:kategori" element={<Dashboard />} />
        </Routes>
        </div>
        <MyFooter />
      </div>
    </Router>
  );
};


export default App;
